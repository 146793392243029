

































































import { allCharacterMetadata, GameId } from "@/tech/AllCharacterMetadata";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  commitDrawer,
  dispatchRestoreState,
  readDrawer,
  readSelectedCharacters,
} from "../../store";
import CharacterSelector from "./CharacterSelector.vue";

@Component({
  name: "TrainingHeader",
  components: {
    CharacterSelector,
  },
})
export default class extends Vue {
  public gameId: GameId = "ssbu";
  public value: number | null | undefined = null;

  @Prop({ required: true, type: Boolean })
  public firstTime!: boolean;

  get selectedCharacter() {
    const selectedCharacters = readSelectedCharacters(this.$store);
    return selectedCharacters[this.gameId];
  }

  get selectedCharacterName(): string {
    const selectedCharacter = this.selectedCharacter;
    if (selectedCharacter === null) {
      return "Not selected";
    } else {
      return allCharacterMetadata[this.gameId][selectedCharacter].displayName;
    }
  }

  public async created(): Promise<void> {
    await dispatchRestoreState(this.$store);
    this.$watch(
      function() {
        return [this.selectedCharacter, this.firstTime];
      },
      function(value) {
        const [newSelectedCharacter, newFirstTime] = value;
        if (newSelectedCharacter === null) {
          this.value = 1;
        } else if (newFirstTime) {
          this.value = 0;
        } else {
          this.value = undefined;
        }
      },
      { immediate: true },
    );
  }

  public openGspTracker(): void {
    commitDrawer(this.$store, !readDrawer(this.$store));
  }
}
